import React, { useContext, useState, useEffect } from "react";
import { DealerContext } from "../shared/contexts/DealerContext";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { ExportOutlined } from "@ant-design/icons";
import { Typography } from "@material-ui/core";
import Divider from '@mui/material/Divider';

const BASE_URI = process.env.NEXT_PUBLIC_BASE_URI;

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '79px',
    display: "flex",
    flexDirection: 'column',
    width: "100vw",
    height: "calc(100vh + 79px)",
    backgroundColor: 'white',
    "@media (max-width: 1024px)": {
      height: "100%",
    },
  },
  rootInner: {
    display: "flex",
    flexDirection: 'row',
    width: "100vw",
    height: "100vh",
    "@media not all and (min-width: 1024px)": {
      flexDirection: 'column-reverse',
      height: "100%",
    },
  },
  dealerContainer: {
    flexDirection: 'column',
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgb(229 231 235)",
    overflowY: 'scroll',
    "@media (min-width: 1536px)": {
      minWidth: "35vw",
      maxWidth: "40vw"
    },
    "@media (min-width: 1280px) and (max-width: 1535px)": {
      minWidth: "50vw"
    },
    "@media (min-width: 1024px) and (max-width: 1279px)": {
      minWidth: "60vw",
      height: "100%",
    },
    "@media (max-width: 1024px)": {
      height: "100%",
    },
  },
  header: {
    margin: '1.25rem 2rem',
    color: 'black',
    fontWeight: 800,
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  dealerCard: {
    padding: '1.25rem',
    marginBottom: '1.25rem',
    marginRight: '2rem',
    marginLeft: '2rem',
    backgroundColor: 'white',
    // maxHeight: '300px',
    "@media not all and (min-width: 640px)": {
      marginLeft: '1rem',
      marginRight: '1rem',
    }
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: 'black',
    padding: '1.25rem 0rem',
    "@media not all and (min-width: 1024px)": {
      flexDirection: 'column',
    }
  },
  smIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem',
    alignSelf: 'baseline'
  },
  hoursText: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0rem',
    whiteSpace: 'no-wrap',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    paddingBottom: '4px',
    height: '100%',
    lineHeight: '15px',
    fontSize: '15px'
  },
  hoursTRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0.5rem',
    whiteSpace: 'no-wrap',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    height: '100%',
    "@media (min-width: 1024px)": {
      minHeight: '30px',
    }
  },
  hoursContainer: {
    display: "flex",
    flexDirection: 'column',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    "@media (min-width: 1024px)": {
      width: '40%',
    }
  },
  map: {
    display: 'flex',
    width: '100%',
    height: '100%',
    '@media not all and (min-width: 1024px)': {
      height: '24rem'
    }
  },
  button: {
    display: "flex",
    flexDirection: 'row',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    color: 'white',
    backgroundColor: '#003145',
    padding: '0.75rem',
    marginTop: '1.25rem',
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    "@media (min-width: 640px)": {
      margin: '0rem 0.625rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  buttonRow: {
    display: "flex",
    flexDirection: 'row',
    paddingTop: '15px',
    width: '100%',
    height: 'auto',
    justifyContent: 'space-between',
    "@media not all and (min-width: 640px)": {
      flexDirection: 'column',
    }
  },
  urlAnchor: {
    "@media not all and (min-width: 1024px)": {
      fontSize: ' 0.875rem',
      lineHeight: '1.25rem',
      width: '300px'
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  urlDiv: {
    display: "flex",
    flexDirection: 'row',
    margin: '0.5rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    height: '100%',
  },
  phoneDiv: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    margin: '0.5rem',
  },
  infoSection: {
    display: "flex",
    flexDirection: 'column',
    "@media (min-width: 768px)": {
      width: '60%',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    }
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3rem',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto',
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
}));

const Dealers = () => {
  const { dealerList, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ ...websiteColors });

  const [expandedDealerList, setExpandedDealerList] = useState([]);
  // const aSeperateDealers = ['Mekor Honda Motorcycles', 'Auto Atlantic Cars', 'JAC Century City'];

  useEffect(() => {
    const processDealers = async () => {
      if (dealerList?.length > 0) {
        const getDealerWebsites = async (dealerID) => {
          const result = await axios({
            method: 'GET',
            url: `${BASE_URI}/DealerWebsites/${dealerID}`,
          })
            .then((res) => res.data.links)
            .catch(() => null);
          return result;
        };

        const l = await dealerList.reduce(async (ac, v) => {
          const acc = await ac;
          const links = getDealerWebsites(v?.dealerId);
          if (links?.length > 0) {
            const mapURL = links.find((lVal) => lVal.name === 'mapsURL').link;
            const mapEmbed = links.find((lVal) => lVal.name === 'mapsEmbed').link;
            return [...acc, { ...v, mapURL, mapEmbed }];
          }
          return [...acc, v];
        }, []);
        setExpandedDealerList(l);
      }
    };

    processDealers();
  }, [dealerList]);

  const operatingHoursSet1 = (ID) => {
    switch (ID) {
      case 560: //Cape Gate
      case '560':
        return (
          <p className={classes.hoursText}>Mon - Fri: 9:00 - 18:00</p>
        )
      default:
        return (
          <p className={classes.hoursText}>Mon - Fri: 8:00 - 17:00</p>
        )
    }
  };

  const operatingHoursSet2 = (ID) => {
    switch (ID) {
      case 560: //Cape Gate
      case '560':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={classes.hoursText}>Saturday: 9:00 - 16:00</p>
          </div>
        )
      case 563: //Tygervalley
      case '563':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={classes.hoursText}>Saturday: 9:00 - 13:00</p>
          </div>
        )
      case 562: //Kenilworth
      case '562':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={classes.hoursText}>Saturday: 9:00 - 13:00</p>
          </div>
        )
      default:
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={classes.hoursText}>Saturday: 8:00 - 13:00</p>
          </div>
        )
    }
  };

  const operatingHoursSet3 = (ID) => {
    switch (ID) {
      case 560: //Cape Gate
      case '560':
        return (
          <div className={classes.hoursTRow}>
            <img alt="" className={classes.smIcon} src='/clockIcon.svg' />
            <p className={classes.hoursText}>Sunday: 9:00 - 13:00</p>
          </div>
        )
      default:
        return null
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        <div className={classes.dealerContainer}>
          <div className={classes.middleNew}>
            <Typography variant="h1" className={classes.middleTile}>
              <strong><span className={classes.newSecondaryColor}>Our</span> Suzuki Dealers</strong>
            </Typography>
            <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
          </div>
          {expandedDealerList
            ?.sort((a, b) => {
              return a.name > b.name ? 1 : -1;
            })
            .map((dealer, index) => {
              return (
                <div key={index} className={classes.dealerCard} >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img alt="" style={{ width: '1.5rem', height: '1.5rem', margin: '0.5rem' }} src='/pinIcon.svg' />
                    <div style={{ display: 'flex', flexDirection: 'column', color: 'black' }}>
                      <h6 style={{ fontWeight: 700, fontSize: '1.25rem', lineHeight: '1.75rem', margin: '0px' }} >{dealer.name}</h6>
                      <a
                        href={
                          dealer?.mapURL
                            ? dealer.mapURL
                            : `https://www.google.com/maps/@${dealer?.addressLongitude},${dealer?.addressLatitude},16z?entry=ttu`
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          fontSize: '0.875rem',
                          lineHeight: '1.25rem',
                          color: '#003145',
                        }}
                      >
                        {dealer?.address?.streetAddress} , {dealer?.address?.streetAddressPostal}
                      </a>
                    </div>
                  </div>
                  <div
                    className={classes.infoContainer}
                  >
                    <div className={classes.infoSection}>
                      <div className={classes.phoneDiv}>
                        <img alt="" className={classes.smIcon} src='/phoneIcon.svg' />
                        <a
                          href={`tel:${dealer?.newCarsPhoneNumber}`}
                          target="_blank"
                          style={{
                            lineHeight: '15px',
                            fontSize: '15px',
                            color: '#003145'
                          }}
                          rel="noreferrer"
                        >
                          {dealer?.newCarsPhoneNumber}
                        </a>
                      </div>
                      <div className={classes.urlDiv}>
                        <img alt="" className={classes.smIcon} src='/worldIcon.svg' />
                        <a
                          href={`https://${dealer?.websiteUrl.replace(/^(http)(s)?:\/\//, '')}`}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.urlAnchor}
                          style={{
                            color: '#003145',
                            lineHeight: '15px',
                            fontSize: '15px'
                          }}
                        >
                          {dealer?.websiteUrl}
                        </a>
                        &nbsp;
                      </div>
                    </div>
                    <div className={classes.hoursContainer}>
                      <div className={classes.hoursTRow}>
                        <img alt="" className={classes.smIcon} src='/clockIcon.svg' />
                        {/* <p className={classes.hoursText}>Mon - Fri: 8:00 - 17:00</p> */}
                        {operatingHoursSet1(dealer?.dealerId)}
                      </div>
                      <div className={classes.hoursTRow}>
                        <img alt="" className={classes.smIcon} src='/clockIcon.svg' />
                        {/* <p className={classes.hoursText}>Saturday: 8:00 - 13:00</p> */}
                        {operatingHoursSet2(dealer?.dealerId)}
                      </div>
                      {operatingHoursSet3(dealer?.dealerId)}
                    </div>
                  </div>
                  <hr />
                  <div className={classes.buttonRow}>
                    <div className={classes.buttonContainer}>
                      <a
                        href={
                          dealer?.mapURL
                            ? dealer.mapURL
                            : `https://www.google.com/maps/@${dealer?.addressLongitude},${dealer?.addressLatitude},16z?entry=ttu`
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={classes.button}
                      >
                        GET DIRECTIONS
                      </a>
                    </div>
                    <div className={classes.buttonContainer}>
                      <a
                        href={dealer?.websiteUrl}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.button}
                      >
                        WEBSITE <ExportOutlined style={{ marginLeft: '0.75rem' }} />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
          <iframe
            className={classes.map}
            title="mapFrame"
            src="https://www.google.com/maps/d/u/0/embed?mid=11F-kGdYCj0859OFPltaEOaB5bgDsuDk&ehbc=2E312F&noprof=1"
            width="100%"
            height="100%"
            frameborder="0" style={{ border: '0' }}
          // style={{ marginTop: '-46px', height: 'calc(100% + 46px)', overflowY: 'hidden', minHeight: '500px' }}
          // this might be deprecated, but it still makes a difference.
          // frameBorder="0"
          />
        </div>
      </div>
    </div>
  );
};

export default Dealers;