/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { VehicleContext } from '../shared/contexts/VehicleContext'
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import BackImg from '../shared/assets/MapImage.png'
import { BASE_URI, SUZUKI_MASTER_DEALERID } from "../shared/Constants";
import axios from "axios";
import { DealerContext } from "../shared/contexts/DealerContext";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    background: "white",
    width: '100%',
    boxShadow: '0 2px 5px 1px #7b7b7b!important',
    zIndex: 50,
  },
  menuHodlerOuter: {
    width: "100%",
    background: "white",
    padding: '0px 0px',
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    top: "0px",
    zIndex: "20",
  },
  menu: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuHodler: {
    display: "flex",
    width: '100%',
    alignItems: 'center',
    justifyContent: 'end',
    "@media (max-width: 1250px)": {
      display: "none",
    },
    "@media (max-width: 1770px)": {
      paddingRight: '0px',
    },
  },
  Link: {
    padding: "21px 13px",
    color: "#083246",
    fontSize: "10px",
    fontFamily: 'InterBold',
    margin: '8px 0px',
    textTransform: 'uppercase',
    "@media (max-width: 1770px)": {
      fontSize: "10px",
    },
    "&:hover": {
      color: "#209287",
      cursor: "pointer",
    },
  },
  LinkActive: {
    padding: "21px 13px",
    color: "#209287",
    fontSize: "10px",
    fontFamily: 'InterBold',
    textTransform: 'uppercase',
    borderBottom: '4px solid #209287',
    marginBottom: '4px',
    "@media (max-width: 1770px)": {
      fontSize: "12px",
    },
    "&:hover": {
      color: "#209287",
      cursor: "pointer",
    },
  },
  LinkM: {
    padding: "19px 15px",
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
  },
  BurgerHolder: {
    position: "relative",
    display: "none",
    padding: '20px 20px 20px 0px',
    "@media (max-width: 1250px)": {
      display: "block",
      position: "relative",
    },
  },
  Burger: {
    display: "none",
    "@media (max-width: 1250px)": {
      display: "block",
    },
  },
  pattie: {
    minWidth: "20px",
    minHeight: "2px",
    margin: "4px",
    background: "#002a7f",
  },
  img: {
    padding: '10px 10px 10px 20px',
    "@media (max-width: 1250px)": {
      margin: '8px 0px',
    },
  },
  imgHolder: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: "200px",
    height: 'auto',
    objectFit: 'contain',
    "@media (max-width: 1250px)": {
      width: "200px",
    },
  },
  dropDown: {
    transform: ({ showRoomDropDown }) => `translate(0px,${showRoomDropDown}%)`,
    width: "100%",
    right: "0px",
    top: "0px",
    position: `absolute`,
    transition: '0.5s all ease-in',
    background: "#f8f8f8",
    backgroundImage: `url(${BackImg})`,
    padding: "0px 0px",
    zIndex: 11,
    boxShadow: "0 0 5px 0 #d7d6d6 ",
  },
  dropDownMobileOutter: {
    transform: ({ openMenu }) => `translate(${openMenu}%,0%)`,
    background: "#083246",
    width: "75%",
    right: "0px",
    top: "0px",
    bottom: '0',
    position: ({ openMenu }) => `${openMenu ? "fixed" : 'fixed'}`,
    transition: '0.5s all ease-in',
  },
  dropDownMobile: {
    width: "98%",
    right: "0px",
    top: "0px",
    bottom: '0',
    position: ({ openMenu }) => `${openMenu ? "fixed" : 'fixed'}`,
    transition: '0.5s all ease-in',
    background: '#083246',
    padding: "0px 10px",
    zIndex: 2
  },
  dropDownInner: {
    margin: '0 auto',
    padding: '25px 0px',
    flexWrap: 'wrap',
    display: 'flex',
  },
  dropDownInnerHolder: {
    padding: '20px 10px',
    textAlign: 'center',
    maxWidth: 'calc(100% / 5)',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dropDownInnerBanner: {
    background: "#083246",
    margin: '0 auto',
    display: 'flex'
  },
  dropDownInnerOutter: {
    background: "#083246",
    margin: '0 auto',
  },
  dropDownInnerBannerModel: {
    maxWidth: 'calc(100% / 3)',
    width: "100%",
    textAlign: 'center',
    color: 'white',
  },
  showRoomDropDownHolder: {
    position: "relative",
  },
  showRoomDropDown: {
    position: "absolute",
    background: "#274b84",
    whiteSpace: "nowrap",
  },
  showRoomDropDownLink: {
    padding: '16px 0px',
    color: "black",
  },
  showRoomDropDownLinkModel: {
    padding: '16px 0px',
    color: "white",
    cursor: 'pointer',
  },
  Close: {
    fontSize: '20px',
    color: '#219287',
    textAlign: 'right',
    padding: '10px',
    paddingTop: '20px',
    paddingBottom: '0px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  TopTitle: {
    fontSize: '28px',
    fontWeight: 'bolder'
  },
  rootInner: {
    width: '100%',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootInner2: {
    width: '100%',
    maxWidth: '1360px',
    display: 'flex',
    alignSelf: 'center',
  }

}));

const TopBar = () => {
  const [openMenu, setOpenMenu] = useState(100);
  const [showRoomDropDown, setShowRoomDropDown] = useState(-100);
  const [navBarData, setNavBarData] = useState(false);
  let { globalDealer, masterDealer } = useContext(DealerContext)
  const { newVehiclesList } = useContext(VehicleContext)

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/dealerWebsites/${globalDealer?.dealerId}`
    }).then((response) => {
      setNavBarData({ title: response.data.title, subTitle: response.data.subTitle, logo: response.data.image });
      document.title = `${response.data.title}`
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDealer]);

  let obj = {
    openMenu: openMenu,
    showRoomDropDown: showRoomDropDown,
  }
  const classes = useStyles({ ...obj });
  const history = useHistory();

  const handleMenu = () => {
    if (openMenu === 100) {
      setOpenMenu(0);
    } else {
      setOpenMenu(100);
    }
  };

  const handleShowRoomMenu = (e, val) => {
    if (e || val) {
      e.preventDefault()
    }
    if (showRoomDropDown === -100) {
      // setShowRoomDropDown(9);
    } else {
      setShowRoomDropDown(-100);
    }
  };

  const checkActive = () => {
    setShowRoomDropDown(-100)
  }

  const handleRedirect = (data) => {
    handleShowRoomMenu();
    history.push(`/new-cars/${data?.make}/${data?.ownedModelId}`, data);
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        <div className={classes.rootInner2}>
          <Box className={classes.menuHodlerOuter}>
            <Box className={classes.menu}>
              <div className={classes.imgHolder}>
                <div className={classes.img}>
                  <Link
                    to="/"
                  >
                    <img alt="" src={navBarData.logo} width="100%" className={classes.logo}></img>
                  </Link>
                </div>
              </div>
              <div className={classes.BurgerHolder}>
                <div
                  className={classes.Burger}
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <div className={classes.pattie}></div>
                  <div className={classes.pattie}></div>
                  <div className={classes.pattie}></div>
                </div>
                <div className={classes.dropDownMobileOutter}>
                  <div className={classes.dropDownMobile}>
                    <div onClick={() => { handleMenu() }} className={classes.Close}><CloseIcon /></div>
                    <Link
                      to="/new-cars"
                      onClick={() => {
                        handleMenu();
                      }}
                    >
                      <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} className={classes.LinkM}>New</Typography>
                    </Link>
                    <Link
                      to="/pre-owned"
                      onClick={() => {
                        handleMenu();
                      }}
                    >
                      <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} className={classes.LinkM}>Pre-Owned</Typography>
                    </Link>
                    <Link
                      to="/special-deals"
                      onClick={() => {
                        handleMenu();
                      }}
                    >
                      <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} className={classes.LinkM}>
                        Deals
                      </Typography>
                    </Link>
                    <Link
                      to="/book-a-service"
                      onClick={() => {
                        handleMenu();
                      }}
                    >
                      <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} className={classes.LinkM}>
                        Service
                      </Typography>
                    </Link>
                    <Link
                      to="/parts"
                      onClick={() => {
                        handleMenu();
                      }}
                    >
                      <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} className={classes.LinkM}>Parts</Typography>
                    </Link>
                    <Link
                      to="/apply-for-finance"
                      onClick={() => {
                        handleMenu();
                      }}
                    >
                      <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} className={classes.LinkM}>Finance</Typography>
                    </Link>
                    <a
                      href="https://www.selltomekor.co.za/" target="_blank" rel="noreferrer"
                    >
                      <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} className={classes.LinkM}>Sell My Car</Typography>
                    </a>
                    {globalDealer?.dealerId != SUZUKI_MASTER_DEALERID ?
                      (<Link
                        to="/contact-us"
                        onClick={() => {
                          handleMenu();
                        }}
                      >
                        <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} className={classes.LinkM}>Contact Us</Typography>
                      </Link>) :
                      (<Link
                        to="/branch-locator"
                        onClick={() => {
                          handleMenu();
                        }}
                      >
                        <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} className={classes.LinkM}>Our Dealers</Typography>
                      </Link>)
                    }
                    {masterDealer?.websiteColors?.logo &&
                      (
                        <a className="flex px-12" href={masterDealer?.websiteUrl || '/'} target="_blank" rel="noreferrer">
                          <img alt="Mekor Logo" className={classes.logo} width='100%' style={{ padding: '20px', filter: "invert(1)" }} src={masterDealer?.websiteColors?.logo} />
                        </a>
                      )
                    }
                  </div>
                </div>
              </div>
              <Box className={classes.menuHodler}>
                <NavLink
                  to="/new-cars"
                  activeClassName={classes.LinkActive}
                  className={classes.Link}
                // onClick={(e) => { handleShowRoomMenu(e, "s") }}
                >
                  <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }}> <strong>NEW</strong></Typography>
                </NavLink>
                <NavLink
                  to="/pre-owned"
                  activeClassName={classes.LinkActive}
                  className={classes.Link}
                >
                  <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} onClick={() => { checkActive() }}>PRE-OWNED</Typography>
                </NavLink>
                <NavLink
                  to="/special-deals"
                  activeClassName={classes.LinkActive}
                  className={classes.Link}
                >
                  <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} onClick={() => { checkActive() }}>Deals</Typography>
                </NavLink>
                <NavLink
                  to="/book-a-service"
                  activeClassName={classes.LinkActive}
                  className={classes.Link}
                >
                  <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} onClick={() => { checkActive() }}>Service</Typography>
                </NavLink>
                <NavLink
                  to="/parts"
                  activeClassName={classes.LinkActive}
                  className={classes.Link}
                >
                  <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} onClick={() => { checkActive() }}>Parts</Typography>
                </NavLink>
                <NavLink
                  to="/apply-for-finance"
                  activeClassName={classes.LinkActive}
                  className={classes.Link}
                >
                  <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} onClick={() => { checkActive() }}>Finance</Typography>
                </NavLink>
                <a
                  href="https://www.selltomekor.co.za/" target="_blank" rel="noreferrer"
                  className={classes.Link}
                >
                  <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} onClick={() => { checkActive() }}>Sell My Car</Typography>
                </a>
                {globalDealer?.dealerId != SUZUKI_MASTER_DEALERID ?
                  (<NavLink
                    to="/contact-us"
                    activeClassName={classes.LinkActive}
                    className={classes.Link}
                  >
                    <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} onClick={() => { checkActive() }}>Contact Us</Typography>
                  </NavLink>) :
                  (<NavLink
                    to="/branch-locator"
                    activeClassName={classes.LinkActive}
                    className={classes.Link}
                  >
                    <Typography variant="h4" style={{ fontSize: '16px', fontWeight: 800 }} onClick={() => { checkActive() }}>Our Dealers</Typography>
                  </NavLink>)
                }
                {masterDealer?.websiteColors?.logo &&
                  (
                    <a className="flex px-12" href={masterDealer?.websiteUrl || '/'} target="_blank" rel="noreferrer">
                      <img alt="Mekor Logo" className={classes.logo} width='100%' style={{ padding: '20px' }} src={masterDealer?.websiteColors?.logo} />
                    </a>
                  )
                }
              </Box>
            </Box>
          </Box>
          <div className={classes.dropDown}>
            <div className={classes.dropDownInnerOutter}>
            </div>
            <div className={classes.dropDownInner}>
              {newVehiclesList?.map((v) => {
                return (
                  <div className={classes.dropDownInnerHolder} onClick={() => { handleRedirect(v) }}>
                    <img alt="" src={v?.image} width="70%" />
                    {/* <Typography variant="h4" style={{fontSize: '16px', fontWeight: 800}} className={classes.showRoomDropDownLink} >From R {v?.price}</Typography> */}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
