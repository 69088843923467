/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import Logo from '../shared/assets/mainLogo.png'
import { Link } from "react-router-dom";
import FacebookIcon from '../shared/assets/fbLogo.png';
import InstagramIcon from '../shared/assets/instLogo.png';
import TwitterIcon from '../shared/assets/twitLogo.png';
import LinkedInIcon from '../shared/assets/linkedLogo.png';
import { DealerContext } from '../shared/contexts/DealerContext';
import { SUZUKI_MASTER_DEALERID } from '../shared/Constants';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: 'center',
    margin: '0px 0px',
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '40px 100px',
    background: "#083246",
    "@media (max-width: 425px)": {
      padding: '20px 15px'

    },
    "@media (min-width: 426px) and (max-width: 842px)": {
      padding: " 5px 50px",
    },
  },
  innerHolder: {
    width: "100%",
    justifyContent: 'space-evenly',
    display: "flex",
    flexDirection: 'row',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column'
    }
  },
  section: {
    width: 'calc(100%/3)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  sectionFlex: {
    display: 'flex',
    '@media (min-width:769px) and (max-width:1024px)': {
      flexDirection: 'column'
    }
  },
  sectionInnerGroup: {
    width: 'calc(100%/2)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",

    }
  },
  GroupedSection: {
    display: 'flex',
    width: 'calc(100%/3)',
    flexDirection: 'column',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  heading: {
    color: 'white',
    marginTop: '21px',
    marginBottom: '10px',
    fontSize: '24px',
  },
  headingText: {
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
  },
  img: {
    display: 'block',
    margin: '0px auto'
  },
  imgSection: {
    width: 'calc(100%/3)',
    textAlign: 'center',
    '@media (min-width:0px) and (max-width:768px)': {
      width: "100%",
      padding: '0px 15px'
    }
  },
  hr: {
    border: '1px #6c757d70 solid',
    width: '100%',
  },
  legalHolder: {
    background: "#219287",
    display: 'flex',
    justifyContent: 'space-between',
    padding: "5px 100px",
    "@media (max-width: 425px)": {
      padding: " 5px 25px",
    },
    "@media (min-width: 426px) and (max-width: 842px)": {
      padding: " 5px 50px",
    },
  },
  legal: {
    fontSize: '8pt',
    background: "#219287",
    color: "white"
  },
  socialLinkHolder: {
    display: 'flex'
  },
  socialLink: {
    width: 'calc(100%/11)!important',
    color: 'black!important',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: 'calc(100%/6)!important',
    }
  },
  socialLinkIcon: {
    width: '80%!important',
    height: '80%!important',
  }
}))

const Footer = () => {
  const classes = useStyles();
  const { globalDealer } = useContext(DealerContext);
  const currentDate = new Date();
  const aSocials = ["facebook", "instagram", "twitter", "linkedIn"];
  const [anySocial, setAnySocial] = useState(false);

  useEffect(() => {
    if (globalDealer?.social) {
      const anyActiveSocial = aSocials.reduce((ac, v) => {
        if (globalDealer?.social[v]?.length > 0) {
          return true;
        } else
          return ac;
      }, anySocial);

      setAnySocial(anyActiveSocial);
    }
  }, [globalDealer]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.innerHolder}>
          <div className={classes.section}>
            <Typography variant='h1' className={classes.heading}><strong>QUICK LINKS</strong></Typography>
            <div className={classes.sectionFlex}>
              <div className={classes.sectionInnerGroup}>
                <Link to="/new-cars"> <Typography variant='h1' className={classes.headingText} > Showroom   </Typography></Link>
                <Link to="/parts"> <Typography variant='h1' className={classes.headingText} > Parts   </Typography></Link>
                <Link to="/special-deals"> <Typography variant='h1' className={classes.headingText} > Deals </Typography></Link>
                <Link to="/apply-for-finance"><Typography variant='h1' className={classes.headingText} > Finance  </Typography></Link>
              </div>
              <div className={classes.sectionInnerGroup}>
                <Link to="/sell-your-vehicle"><Typography variant='h1' className={classes.headingText} > Sell a Car</Typography></Link>
                {globalDealer?.dealerId != SUZUKI_MASTER_DEALERID ?
                  (
                    <Link to="/contact-us"><Typography variant='h1' className={classes.headingText} >Contact Us</Typography></Link>
                  ) :
                  (
                    <Link to="/branch-locator"><Typography variant='h1' className={classes.headingText} >Our Dealers</Typography></Link>
                  )
                }
                <Link to="/terms"><Typography variant='h1' className={classes.headingText} > Privacy Policy  </Typography></Link>
                <a
                  href="https://www.selltomekor.co.za/" target="_blank" rel="noreferrer"
                  className={classes.headingText}
                >
                  Sell To Mekor
                </a>
              </div>
            </div>
          </div>
          {anySocial ?
            (
              <div className={classes.section}>
                <Typography variant='h1' className={classes.heading} ><strong>FOLLOW US</strong></Typography>
                <div className={classes.socialLinkHolder}>
                  {globalDealer?.social?.facebook.length > 0 ?
                    (
                      <a href={globalDealer?.social?.facebook} className={classes.socialLink} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                        <img alt="" src={FacebookIcon} className={classes.socialLinkIcon} />
                      </a>
                    ) : null
                  }
                  {globalDealer?.social?.instagram.length > 0 ?
                    (
                      <a className={classes.socialLink} href={globalDealer?.social?.instagram} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                        <img alt="" src={InstagramIcon} className={classes.socialLinkIcon} />
                      </a>
                    ) : null
                  }
                  {globalDealer?.social?.twitter.length > 0 ?
                    (
                      <a className={classes.socialLink} href={globalDealer?.social?.twitter} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                        <img alt="" src={TwitterIcon} className={classes.socialLinkIcon} />
                      </a>
                    ) : null
                  }
                  {globalDealer?.social?.linkedIn.length > 0 ?
                    (
                      <a className={classes.socialLink} href={globalDealer?.social?.linkedIn} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                        < img alt="" src={LinkedInIcon} className={classes.socialLinkIcon} />
                      </a>
                    ) : null
                  }
                </div>
              </div>
            ) : null
          }
          {globalDealer?.dealerId != SUZUKI_MASTER_DEALERID &&
            (
              <div className={classes.GroupedSection}>
                <div>
                  <Typography variant='h1' className={classes.heading} ><strong>CHAT TO US</strong></Typography>
                  <Typography variant='h3' className={classes.headingText} ><strong>{globalDealer?.email}</strong></Typography>
                  <a className="px-4 flex-row flex justify-center items-center" href={`tel:${globalDealer?.newCarsPhoneNumber.replaceAll(' ', '')}`} target="_blank" rel="noreferrer">
                    <Typography variant='h3' className={classes.headingText} ><strong>{globalDealer?.newCarsPhoneNumber}</strong></Typography>
                  </a>
                </div>
              </div>
            )
          }
          <div className={classes.imgSection}>
            <Typography variant='h4' className={classes.heading} ><img alt="logo" src={Logo} /></Typography>
            <Typography variant='h4' className={classes.headingText} >Copyright - {globalDealer?.name.replace("Master", "")} {currentDate.getFullYear()} - Privacy Policy</Typography>
            <Typography variant='h4' className={classes.headingText} >All content on this website is<br /> subject to change without notice</Typography>
          </div>
        </div>
      </div>
      {/* <div className={classes.legalHolder}>
        <div variant='h1' className={classes.legal} >
          Terms and Conditions Apply.
        </div>
        <div variant='h1' className={classes.legal} >
          Powered by Reach Engage Develop
        </div>
      </div> */}
    </>
  )
}

export default Footer
