/* eslint-disable eqeqeq */
import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Finance } from "@red-build/leadcentre";
import { Typography } from "@material-ui/core";
import { MASTER_DEALERID, BASE_URI } from '../shared/Constants';
import { DealerContext } from "../shared/contexts/DealerContext";
import axios from 'axios';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: "130px",
    marginBottom: "60px"
  },
  innerSection: {
    width: "100%",
    maxWidth: "1360px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 15px",
    "@media (max-width: 959px)": {
      padding: "1rem",
    },
  },
  section: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50vw",
    marginTop: "5rem",
    "@media (max-width: 959px)": {
      width: "100%",
      marginLeft: '1rem' // Adding this because we have a -1rem somewhere in ui shared.
    },
  },
  text: {
    fontSize: "16px",
  },
  titleTop: {
    fontSize: "44px",
    fontWeight: "bold"
  },
  titleTopAccent: {
    fontSize: "52px",
    color: "#209287",
    fontWeight: "bold"
  },
  imgBlockHolder: {
    color: "#083246",
    fontSize: "26px",
    fontWeight: "bold",
    marginBottom: "10px",
    maxWidth: "490px",
    margin: "auto",
    display: "flex",
    padding: "20px",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: "center",
    paddingTop: "40px",
    color: "#003145"
  },
  middleSubtitle: {
    fontSize: "1.5rem",
    color: "#466574",
    textAlign: "center",
    marginTop: "20px",
  },
  deviderContainer: {
    marginTop: "25px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: "100px",
    height: "5px",
    margin: "auto"
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
}));

const VehicleFinance = () => {
  const classes = useStyles();
  const { globalDealer, dealerList } = useContext(DealerContext)
  const [dealerModels, setDealerModels] = useState()

  useEffect(() => {
    let data =
    {
      pageNumber: 1,
      dealerId: 438,
      pageSize: 1000
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/dealermodels`,
      data
    }).then((response) => {
      setDealerModels(response.data.list.filter((v) => v.makeId == 8074))
    })
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
          <Typography variant="h1" className={classes.middleTile}>
            <strong><span className={classes.newSecondaryColor}>Apply</span> For Finance</strong>
          </Typography>
          <Typography variant="h2" className={classes.middleSubtitle}>
            Leave a message, we will get back to you.
          </Typography>
          <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
        <div className={classes.section}>
          <Finance
            masterDealerId={MASTER_DEALERID}
            dealerId={globalDealer?.dealerId}
            pqType={"interim_pq"}
            dealerModels={dealerModels}
            dealers={dealerList}
            base_uri={BASE_URI}
            dealerType={"multi_dealer"}
            leadTypeId={12}
          />
        </div>
      </div>
    </div>

  );
};

export default VehicleFinance;
