/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useMediaQuery from "../../shared/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    position: 'relative',
    overflow: 'hidden',
    margin: '20px 0px',
    marginBottom: '0px',
    '@media (max-width: 425px)': {
      height: '100%',
    },
  },
  sliderContainerInner: {
    marginTop: "0em",
    marginBottom: "0em",
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    transition: 'all 0.5s ease',
    justifyContent: 'flex-start',
    overflowX: 'scroll',
    transform: ({ slidePosition }) => (!slidePosition == 0 ? `translateX(${slidePosition}%)` : `translateX(0%)`),
    "@media (max-width: 600px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
    "@media (min-width: 601px) and (max-width: 1333px)": {
      transform: ({ slidePosition }) => (!slidePosition <= 0 ? `translateX(${slidePosition * 3}%)` : `translateX(0%)`),
    },
  },
  vehicleCardGrid: {
    marginBottom: '30px',
    boxShadow: "0px 28px 20px -18px #001e4c70",
    maxWidth: 'calc(100%/3 - 20px)',
    minWidth: 'calc(100%/3 - 20px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '10px',
    position: 'relative',
    border: '1px solid #e2e3e5',
    background: 'white',
    '@media (max-width: 500px)': {
      width: 'unset',
      maxWidth: 'unset',
      minWidth: 'calc(100% / 1 - 20px)',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      width: 'unset',
      maxWidth: 'unset',
      minWidth: 'calc(100% / 2 - 20px)',
    },
  },
  vehicleCardText: {
    width: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column'
  },
  brand: {
    padding: '0px 0px',
    marginBottom: '0px',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  accent: {
    color: 'red',
    fontSize: '16px',
  },
  vehicleCardGridText: {
    padding: '15px',
  },
  vehicleCardGridTextGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px'
  },

  vehicleCardGridTextFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnDetails: {
    border: '2px solid red',
    background: 'transparent',
    padding: '6px 16px',
    width: 'fit-content',
    color: " red",
    borderRadius: '24px',
    margin: '15px auto',
    display: 'block',
    '&:hover': {
      background: 'red    ',
      color: " white",
    }
  },
  title: {
    padding: '10px 0px',
    marginBottom: '15px',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  slide: {
    maxWidth: 'calc(100% / 4 - 20px)',
    width: '100%',
    margin: '10px',
  },
  btnNext: {
    background: '#2c88d9',
    padding: '10px 10px',
    border: 'none',
    position: 'absolute',
    zIndex: 50,
    top: '50%',
    right: "-35px",
    opacity: '0.4',
    color: 'white',
    transform: 'translate(0%, -50%)',
    '&:hover': {
      opacity: '1',
    }
  },
  btnBack: {
    background: '#2c88d9',
    padding: '10px 10px',
    border: 'none',
    position: 'absolute',
    zIndex: 50,
    top: '50%',
    left: "-35px",
    opacity: '0.4',
    color: 'white',
    transform: 'translate(0%, -50%)',
    '&:hover': {
      opacity: '1',
    }
  },
  stockNo: {
    color: "#808080a1",
    fontSize: ''
  },
  linkS: {
    color: "black",
  },
  imgHolder: {
    display: 'block',
    lineHeight: '0px',
  },
}));

const CarouselCar = ({ slides, innerClass, imgClass, dots, }) => {
  const [slidePosition, setSlidePosition] = useState(0);
  const classes = useStyles(slidePosition);
  const [slideIndex, setSlideIndex] = useState(0)
  const [btnShow, setBtnShow] = useState(false)
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 1333px)');
  const isDesktop = useMediaQuery('(min-width: 1334px)');

  useEffect(() => {
    if (isDesktop) {
      if (slides?.length > 3) {
        setBtnShow(true)
      }
    }
    if (isTablet) {
      setBtnShow(true)
    }
    if (isMobile) {
      setBtnShow(true)

    }
  }, [])

  const handleNext = () => {
    if (isDesktop) {
      if (slideIndex < slides.length - 4) {
        setSlidePosition(slidePosition - (100 / 4))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === slides.length - 4) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }
    if (isMobile) {
      if (slideIndex < slides.length - 1) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === slides.length - 1) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }
    if (isTablet) {
      if (slideIndex < slides.length - 3) {
        setSlidePosition(slidePosition - (100 / 3))
        setSlideIndex(slideIndex + 1)
      }
      if (slideIndex === slides.length - 3) {
        setSlidePosition(0)
        setSlideIndex(0)
      }
    }
  }
  const handleBack = () => {
    if (slidePosition < 0) {
      if (isDesktop) {
        setSlidePosition(slidePosition + (100 / 4))
        setSlideIndex(slideIndex - 1)
      }
      if (isMobile) {
        setSlidePosition(slidePosition + (100 / 1))
        setSlideIndex(slideIndex - 1)
      }
      if (isTablet) {
        setSlidePosition(slidePosition + (100 / 3))
        setSlideIndex(slideIndex - 1)
      }
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.sliderContainer}>
        <div className={classes.sliderContainerInner}>
          {
            slides?.map((v, index) => {
              if (v.imageUrl == "") {
                v.imageUrl = ""
              }
              return (
                <div className={classes.vehicleCardGrid}>
                  <Link component={RouterLink} to={"/special-deals/" + v?.make?.toLowerCase() + "/" + v.id} className={classes.linkS}>
                    <>
                      <div className={classes.before}>
                      </div>
                      <div className={classes.imgHolder}>
                        <img alt="" src={v.imageUrl} width="100%" height="100%" />
                      </div>
                    </>
                  </Link>
                </div>
              )
            }
            )
          }
        </div>
      </div>
      {btnShow &&
        <>
          <button className={classes.btnBack} onClick={() => { handleBack() }}><ArrowBackIosNewIcon /></button>
          <button className={classes.btnNext} onClick={() => { handleNext() }}><ArrowForwardIosIcon /></button>
        </>
      }
    </div>
  );
}

export default CarouselCar;