import React, { useContext } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { MASTER_DEALERID, BASE_URI, MOTORGROUPID } from '../shared/Constants'
import { Service } from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext'
import { VehicleContext } from '../shared/contexts/VehicleContext';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingTop: '130px',
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: '60px'
  },
  innerSection: {
    width: "100%",
    maxWidth: "1360px",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center',
    padding: "6px 15px",
  },
  section: {
    width: '50vw',
    margin: '5rem',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      '&:nth-child(1)': {
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: '#209287',
    fontWeight: 'bold'
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  middleSubtitle: {
    fontSize: "1.5rem",
    color: "#466574",
    textAlign: 'center',
    marginTop: '20px',
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto'
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
}))

const BookService = () => {
  const classes = useStyles();
  const { dealerList, globalDealer } = useContext(DealerContext)
  const { newVehiclesList } = useContext(VehicleContext)

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.middleNew}>
          <Typography variant="h1" className={classes.middleTile}>
            <strong><span className={classes.newSecondaryColor}>Book</span> A Service</strong>
          </Typography>
          <Typography variant="h2" className={classes.middleSubtitle}>
            Leave your details and one of our service advisors will contact you shortly.
          </Typography>
          <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
        </div>
        <div className={classes.section}>
          <Service
            base_uri={BASE_URI}
            //serviceType='standard' 
            dealerName={globalDealer?.name?.replace("Master", "")}
            //dealerId={4}
            activeMake="SUZUKI"
            activeMakeId={8074}
            leadTypeId={5}
            masterDealerId={MASTER_DEALERID}
            dealerId={globalDealer?.dealerId}
            motorGroupId={MOTORGROUPID}
            dealerList={dealerList}
            models={newVehiclesList}
            hasMultiDealers={true}
          />
        </div>
      </div>
    </div>

  )
}

export default BookService