import React from 'react';

const BannerCTA = ({ slides, containerStyle, contentStyle, textStyle }) => {

  return (
    <div style={containerStyle}>
      {slides?.map((v, i) => (
        <div key={`${i}`} style={ v?.component ? contentStyle : {display: 'flex', width: '100%', height: '100%'}}>
          {v?.component ?
            v?.component : (
              <a key={`link_to_${v.to}`} style={{display:'flex', width: '100%', height: '100%'}} href={v.toHref}>
                <div style={contentStyle} >
                  <img alt='' style={{objectFit: 'cover', width: '100%', height: '100%'}} src={v.src} />
                  <div style={textStyle}>
                    <p>
                      {v.text}
                    </p>
                  </div>
                </div>
              </a>
            )}
        </div>
      ))}
    </div>
  )
}

export default BannerCTA;