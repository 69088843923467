export default function Custom404() {
  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '10rem', justifyContent: 'flex-start', alignItems: 'center' }}>
      <h1 style={{ color: 'black', fontWeight: 800, textAlign: 'center', width: '100vw', fontSize: '35px' }}>Error 404 - Page not found</h1>
      <h2
        style={
          {
            color: 'black',
            textAlign: 'center',
            width: '100vw',
            fontSize: '20px'
          }
        }>
        The link you tried to access is currently missing or broken.
        Please feel free to browse the rest of our site.
        {/* A few useful links are listed below. */}
      </h2>
    </div>
  )
}