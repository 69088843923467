export const BASE_URI = "https://conexa.r-e-d.co.za/api";
export const WEPAY4CARS_URI = "https://c2-wepay4cars-marketdealer.azurewebsites.net/api";
export const PAGE_SIZE = 20;
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;
export const SUZUKI_MASTER_DEALERID = 584
export const MASTER_DEALERID = 552
export const MASTER_CONFIGURATOR_DEALERID = 286
export const MASTER_DEALER_NAME = "Mekor Suzuki Master"
export const MOTORGROUPID = 83
export const FINAPP_URI_BASE = "https://dz6rhlcy38qhy.cloudfront.net";
export const FINAPP_URI_APPLICATION = `${FINAPP_URI_BASE}/${MASTER_DEALERID}/1/vehicle/finance/0/:leadId`;