import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: 'white',
    maxWidth: '1360px',
    padding: '130px 50px 50px 50px',
    textAlign: 'justify',
  },
  link: {
    color: 'blue',
    textDecoration: 'none',
    '&:hover': {
      color: 'blue',
      textDecoration: 'none'
    }
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '3rem',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto',
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
  rootInner: {
    maxWidth: '1360px',
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
}));

const Terms = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" >
      <div className={classes.content}>
        <div className={classes.middleNew}>
          <Typography variant="h1" className={classes.middleTile}>
            <strong><span className={classes.newSecondaryColor}>TERMS</span> & CONDITIONS</strong>
          </Typography>
          <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
        </div>
        <br />
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500" >
          Copyright
        </Typography>
        <p>
          All content included on this web site, such as text, graphics, logos, button icons, images, audio clips, and software, is the property of Mekor Suzuki Motors or its content suppliers and protected by South African and international copyright laws. Furthermore, the compilation (meaning the collection, arrangement, and assembly) of all content on this web site is the exclusive property of Mekor Suzuki Motors and protected by South Africa and international copyright laws.
        </p>
        <p>
          Without derogating from the above, Mekor Suzuki Motors authorises you to view, copy, download to a local drive, print and distribute the content of this web site, or parts thereof, provided that: Such content is used for information purposes only; Such content is used for non-commercial purposes. You are expressly prohibited from incorporating any of the material from this web site in any other work, publication or web site of your own or belonging to another. Any reproduction of material from this web site or portion thereof must include this notice in its entirety.
        </p>
        <br />
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Liability Disclaimer
        </Typography>
        <p>
          The information, services, products and materials published on this web site, including without limitation, text, graphics and links are provided on an “as is” basis. Mekor Suzuki Motors makes no representations or warranties of any kind, express or implied, as to the operation of this web site or the information, contents, materials, or products included on this site. Without limiting the generality of the a foregoing: Mekor Suzuki Motors does not warrant that this web site, will be error free, or will meet any particular criteria of accuracy, completeness or reliability of information, performance or quality; and Whilst Mekor Suzuki Motors has taken reasonable measures to ensure the integrity of this web site and its contents, no warranty, whether express or implied, is given that any files, downloads or applications available via this web site are free of viruses, Trojans, bombs, time-locks or any other date or code which has the ability to corrupt or affect the operation of your system.
        </p>
        <br />
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Limitation of Liability
        </Typography>
        <p>
          In no event shall Mekor Suzuki Motors and its suppliers be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the use of this web site. Such limitation shall also apply with respect to damages resulting from the inability to use this web site, the operational failure of this web site, or for any information, products, and services obtained through this web site, or otherwise arising out of the use of this web site, whether based on contract, delict, strict liability or otherwise, even if Mekor Suzuki Motors and/or any of its suppliers has been advised of the possibility of damages.
        </p>
        <br />
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Professional Information
        </Typography>
        <p>
          This web site is only intended to provide general information regarding Mekor Suzuki Motors and its products and services and it is not intended to provide exhaustive treatment of any subject dealt with. The information on this web site including, without limitation, all research, opinions or other content is therefore not intended nor does it constitute financial, accounting, tax, legal, investment, consulting or other professional advice or services.
        </p>
        <p>Before making any decision or taking any action which might affect you or your business you should consult your own professional advisors.</p>
        <p>
          This web site could include technical, typographical or other inaccuracies and you are urged to contact Mekor Suzuki Motors to confirm all information contained on this web site prior to placing reliance thereon. Changes are periodically made to the information herein and these changes will be incorporated in new editions of this web site.
        </p>
        <br />
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Applicable Law
        </Typography>
        <p>
          These terms and conditions shall be governed by and construed in accordance with the laws of the Republic of South Africa without giving effect to the principles of conflicts of laws. You hereby consent to the exclusive jurisdiction of the High Court of the Republic of South Africa in respect of any disputes arising in connection with this web site.
        </p>
        <br />
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Privacy Policy
        </Typography>
        <p>Mekor Suzuki Motors recognises the importance of protecting your personal information. Therefore, we have put together a privacy policy and approach that respects and addresses your needs.</p>
        <p>
          We define personal information as any and all of the information specific to you that you provide to us over the Internet or any other channel. This includes personal data as varied as the information you enter into an online application for a new account, questions you ask via e-mail or personal banking data.
        </p>
        <p>
          How does Mekor Suzuki Motors use the personal information you provide? Mekor Suzuki Motors has the highest regard for the privacy of its customers. Mekor Suzuki Motors will use personal information as appropriate in the normal course of our business to provide the products and services you have requested. We will also use this information to provide positive identification of you when you contact us. On occasion, we also send e-mail updates on services and other materials we believe may be of importance or interest.
        </p>
        <p>
          Does Mekor Suzuki Motors provide your personal information to external parties? Absolutely not! Mekor Suzuki Motors does not and will not sell or provide personal information to third parties for independent use. We may, however, share personal information with business partners if that information is required to provide the product or service you have requested. They adhere to using information only as directed by Mekor Suzuki Motors.
        </p>
        <p>
          Mekor Suzuki Motors engages third parties that help it deliver its banner advertisements and other online communications. The third parties may collect and use information about Mekor Suzuki Motors customers to help us understand the offers, promotions, and types of advertising that are most appealing to its customers. The personal information they collect is aggregated and cannot be linked to a person. If you do not wish your personal information to be used in this way, please notify us.
        </p>
        <p>– Third party vendors, including Google and DoubleClick, show Mekor Suzuki Motors ads on sites on the internet.</p>
        <p>– Third party vendors, including Google and DoubleClick, use cookies to serve ads based on a user’s prior visits to Mekor Suzuki Motors website.</p>
        <p>– Users may opt out of Google and DoubleClick’s use of cookies by visiting the Google advertising opt-out page or by visiting the Network Advertising Initiative opt out page.</p>
        <br />
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Violation and Waiver
        </Typography>
        <p>
          Should you violate these Terms and Conditions or any other rights of Mekor Suzuki Motors, Mekor Suzuki Motors reserves the right to pursue any and all legal and equitable remedies against you. If Mekor Suzuki Motors should fail to enforce any right or provision in these Terms and Conditions, you agree that this failure does not constitute a waiver of such right or provision or of any other rights or provisions in these Terms and Conditions. If a court should find that one or more rights or provisions set forth in these Terms and Conditions are invalid, you agree that the remainder of the Terms and Conditions shall be enforceable and that, to the extent permitted by law, the court shall give effect to the parties’ intentions, as reflected in any such right or provision that has been declared invalid or unenforceable.
        </p>
        <br />
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Tracking Analytics
        </Typography>
        <p>
          We use <Link component={RouterLink} to="/cookies" className={classes.link}>"cookies" </Link>on this webiste.
        </p>
        <br />
        <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
          Contacting Us
        </Typography>
        <p>
          If there are any questions regarding our Terms & Conditions, <Link component={RouterLink} to="/contact-us" className={classes.link}>Contact Us</Link>
        </p>
        <br />
        <br />
        <div>
          <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
            Additional Links
          </Typography>
          <ul>
            <li>
              <Link component={RouterLink} to="/requestergws" className={classes.link}>Personal information request form</Link>
            </li>
            <li>
              <Link component={RouterLink} to="/cookies" className={classes.link}>Cookie Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </Container>
  )
}

export default Terms