import React from "react";
import { makeStyles } from "@material-ui/core";
import ShowRoom from "./Showroom";
import { Typography } from "@material-ui/core";
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingTop: '85px',
    display: "flex",
    flexDirection: 'column',
    "@media (max-width: 500px)": {
      padding: "0px 0px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      padding: "0px 0px",
    },
  },
  innerHolder: {
    // background: 'white',
    display: "flex",
    flexWrap: "wrap",
    justifyContent: 'center',
    maxWidth: '1260px',
    margin: " 0 auto",
    padding: "50px",
    width: '100%',
    "@media (max-width: 500px)": {
      width: '100%',
      padding: " 20px 20px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      width: '100%',
      padding: " 20px 20px",
    },
  },
 middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  middleSubtitle: {
    fontSize: "1.5rem",
    color: "#466574",
    textAlign: 'center',
    marginTop: '20px',
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto'
  },
  newSecondaryColor: {
    color: "#53aca2",
  }, 
}));

const ShowRoomPage = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.innerHolder}>
          <div className={classes.middleNew}>
            <Typography variant="h1" className={classes.middleTile}>
              <strong><span className={classes.newSecondaryColor}>View</span> Our Range</strong>
            </Typography>
            <Typography variant="h2" className={classes.middleSubtitle}>
              {/* explore Suzuki's range of affordable cars */}
            </Typography>
            <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
          </div>
          <ShowRoom />
        </div>
      </div>
    </>
  );
};

export default ShowRoomPage;
