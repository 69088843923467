import React, { useState, useEffect, useContext } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { VehiclesFilterV2 } from "@red-build/leadcentre";
import { BASE_URI, MASTER_DEALERID } from "../shared/Constants";
import { useHistory } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { DealerContext } from "../shared/contexts/DealerContext";
import axios from "axios";
import { TagOutlined, CalendarOutlined } from "@ant-design/icons";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '135px',
    width: "100%",
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    "@media (max-width: 768px)": {},
  },
  innerHolderFilter: {
    maxWidth: "1360px",
    width: '100%',
    margin: "60px 60px",
  },
  innerHolder: {
    maxWidth: "1360px",
    display: "flex",
    flexWrap: "wrap",
    margin: " 0 auto",
    width: "100%",
  },
  card: {
    width: "calc(100% / 3 - 20px )",
    minWidth: "calc(100% / 3 - 20px )",
    cursor: 'pointer',
    boxShadow: "0 0 5px 0 #d7d6d6 ",
    margin: "10px 10px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: 'white',
    borderRadius: '10px',
    "@media (max-width: 700px)": {
      width: "100%",
    },
    "@media (min-width: 701px) and (max-width: 999px)": {
      width: "calc(100% / 2 - 20px )",
    },
    "@media (min-width: 1000px) and (max-width: 1500px)": {
      width: "calc(100% / 3 - 20px )",
    },
  },
  cardTextHolder: {
    padding: "20px 10px",
    "@media (max-width: 768px)": {},
  },
  cardImgHolder: {
    margin: '0px',
    padding: '0px',
    height: '300px',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    "@media (max-width: 768px)": {
      height: '250px',
    },
  },
  cardTextHolderGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '16px',
    mergin: '0px',
    padding: '0px'
    // { fontheight: '17px', width: '17px', marginRight: '0.2rem' }
  },
  cta: {
    border: "none",
    minWidth: '47%',
    borderRadius: '1rem',
    padding: "10px 40px",
    background: "#083246",
    color: "white",
    cursor: "pointer",
    margin: '0px 5px 0px 5px',
    display: 'block',
    "&:hover": {
      background: "  #083246d1",
      boxShadow: "0 0 5px 0  #083246d1",
    },
    "@media (max-width: 1024px)": {
      minWidth: '100%',
      margin: '5px 0px 5px 0px',
    },
  },
  cardTextTitleHolder: {
    marginBottom: '10px',
  },
  cardTextTitle3: {
    fontSize: '19px',
    fontWeight: '500',
    lineHeight: '19px',
    marginTop: '0.5rem',
    marginBottom: '0.5rem'
  },
  cardTextTitle2: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '19px',
    margin: '0.5rem 0rem 0rem 1rem',
  },
  cardTextTitle1: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    fontSize: '24px',
    fontWeight: '700',
    color: "#083246",
    margin: '0.5rem 0rem 0.5rem 1rem',
  },
  pagHolder: {
    display: 'flex',
    justifyContent: 'center'
  },
  pagBtn: {
    border: "none",
    borderRadius: '1rem',
    background: "#083246",
    color: "white",
    cursor: "pointer",
    margin: '10px',
    padding: '10px 40px',
    "&:hover": {
      background: "  #083246d1",
      boxShadow: "0 0 5px 0  #083246d1",
    },
  },
  pagNumberHolder: {
    textAlign: 'right',
    width: '100%',
    padding: '5px 10px',
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  middleSubtitle: {
    fontSize: "19px",
    color: "#466574",
    textAlign: 'center',
    margin: '0px 3px 0px 0px',
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto'
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
  textSubtitle: {
    fontSize: '16px',
    margin: '0px',
    lineHeight: '16px',
    fontWeight: '200'
  },
  textInfo: {
    fontSize: '16px',
    margin: '2px 0px 2px 0px'
  },
  textCTA: {
    margin: '10px 0px 10px 0px',
    display: 'flex',
    fontSize: '16px',
    flexDirection: 'row',
    alignItems: 'center',
    "&:hover": {
      color: "#53aca2",
      textDecoration: 'underline',
      textDecorationColor: "#53aca2",
    },
  },
  ctaSection: {
    display: 'flex',
    flexDirection: 'row',
    "@media (max-width: 1024px)": {
      flexDirection: 'column',
    },
  }
}));

const UsedVehicles = () => {
  const history = useHistory();
  const classes = useStyles();
  const { loading, vehiclesList } = useContext(VehicleContext);
  const { dealerList, globalDealer } = useContext(DealerContext);
  const [vehiclesPag, setVehiclesPag] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setCount] = useState(20);
  const [dealerLinks, setDealerLinks] = useState();

  const totalPages = ((vehiclesList?.length / 20) + 1).toFixed(0);

  const handleRedirect = (v) => {
    history.push(`/pre-owned/${v.brand.toLowerCase()}/${v.stockId}`, v);
  };

  const defaultColors = {
    primaryColor: "white",
    secondaryColor: "#53aca2",
    labelColor: "#083246",
    InputColor: "red",
    borderColor: '#083246',
    background: '#ffffff00',
    selectColor: "#083246",
    trackColor: "#083246",
    checkColor: "#083246",
  };
  const multiSelect = true;

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  useEffect(() => {
    let startIndex = 0
    let endIndex = 20
    let pag = []

    for (let index = 0; index <= vehiclesList?.length / 20; index++) {
      pag?.push(vehiclesList?.slice(startIndex, endIndex))
      startIndex = endIndex
      endIndex = endIndex + 20
    }

    setVehiclesPag(pag)
  }, [vehiclesList])


  useEffect(() => {
    if (globalDealer?.dealerId) {
      const getDealerWebsites = async (dealerID) => {
        axios({
          method: 'GET',
          url: `${BASE_URI}/DealerWebsites/${dealerID}`,
        })
          .then((res) => {
            const oLinks = res.data.links.reduce((ac, v) => {
              return { ...ac, [v?.name.replace(' ', '')]: v?.link }
            }, {});
            setDealerLinks(oLinks);
          })
          .catch(() => null);
      };

      getDealerWebsites(globalDealer.dealerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNextPage = () => {
    if (vehiclesPag[pageIndex].length >= 20) {
      setCount(count + 20)
      setPageIndex(pageIndex + 1)
    }
  }
  const handleBackPage = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1)
    }
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.middleNew}>
          <Typography variant="h1" className={classes.middleTile}>
            <strong><span className={classes.newSecondaryColor}>Our</span> Showroom</strong>
          </Typography>
          <Typography variant="h2" className={classes.middleSubtitle}>
            {/* explore Suzuki's range of affordable cars */}
          </Typography>
          <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
        </div>
        {!loading &&
          <div className={classes.innerHolderFilter}>
            <VehiclesFilterV2
              pageSize={20}
              dealerId={MASTER_DEALERID}
              motorgroupId={76}
              orientation='row'
              updateFilteredVehicles={setVehiclesPag}
              vehicles={vehiclesList}
              base_uri={BASE_URI}
              websiteColors={defaultColors}
              multiSelect={multiSelect}
            />
          </div>
        }
        <div className={classes.innerHolder}>
          {vehiclesPag[pageIndex]?.map((v) => {
            const dealer = dealerList.find((d) => d.dealerId == v.dealerId);
            return (
              <div
                className={classes.card}
                onClick={() => {
                  handleRedirect(v);
                }}
              >
                <div className={classes.cardTextTitleHolder}>
                  <h3 className={classes.cardTextTitle1}>{v.brand} {v.model}</h3>
                  <h3 className={classes.cardTextTitle2}>{v.modelRange} - {v.year}</h3>
                </div>
                <div className={classes.cardImgHolder} style={{ backgroundImage: `url(${v?.image})` }} />
                <div className={classes.cardTextHolder}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px' }}>
                    <h4 className={classes.cardTextTitle3}>{formatter?.format(v?.price)}</h4>
                  </div>
                  <div className={classes.cardTextHolderGroup}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px' }}>
                      <TagOutlined style={{ padding: '0px 0px 0px 0px', margin: '0px 6px 0px 0px' }} />
                      <p style={{ padding: '0px 0px 0px 0px', margin: '0px 6px 0px 0px' }}>{v.model} {v?.modelRange}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px' }}>
                      <img alt="" style={{ width: '18px', hiehgt: '18px', marginRight: '10px' }} src='/testDrive.svg' />
                      <p>{v?.mileage} km</p>
                    </div>
                  </div>
                  <div className={classes.cardTextHolderGroup}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px' }}>
                      <CalendarOutlined style={{ padding: '0px 0px 0px 0px', margin: '0px 6px 0px 0px' }} />
                      <p style={{ padding: '0px 0px 0px 0px', margin: '0px 6px 0px 0px' }}>{v?.year}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px' }}>
                      <img alt="" style={{ width: '18px', hiehgt: '18px', marginRight: '10px' }} src='/transmission.svg' />
                      <p>{v?.transmission}</p>
                    </div>
                  </div>

                  <div className={classes.cardTextHolderGroup}>
                    <a
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px', color: 'black' }}
                      href={
                        dealerLinks?.mapsURL
                          ? dealerLinks.mapsURL
                          : `https://www.google.com/maps/@${globalDealer?.addressLongitude},${globalDealer?.addressLatitude},16z?entry=ttu`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p>
                        <img src='/pinIcon.svg' alt='Dealer location' style={{ height: '18px', width: '18px', marginRight: '10px', color: 'black' }} />
                        {v?.dealershipName}
                      </p>
                    </a>
                    <a
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px' }}
                      href={`tel:${dealer?.usedCarsPhoneNumber.replaceAll(' ', '')}`}
                      target="_blank"
                      rel="noreferrer">
                      <p>
                        <img src='/phoneIcon.svg' alt='Dealer location' style={{ height: '18px', width: '18px', marginRight: '10px' }} />
                        {dealer?.usedCarsPhoneNumber}
                      </p>
                    </a>
                  </div>
                  <div className={classes.ctaSection}>
                    <button className={classes.cta}>View</button>
                    <button className={classes.cta}>Enquire Now</button>
                  </div>
                </div>
              </div>
            );
          })}
          <div className={classes.pagNumberHolder}>{pageIndex + 1} / {totalPages - 1}</div>
        </div>
        <div className={classes.pagHolder}>
          {pageIndex > 0 && (<button className={classes.pagBtn} onClick={() => { handleBackPage() }}>Previous</button>)}
          {vehiclesPag[pageIndex]?.length >= 20 && (<button className={classes.pagBtn} onClick={() => { handleNextPage() }}>Next</button>)}
        </div>
      </div>
    </>
  );
};

export default UsedVehicles;
