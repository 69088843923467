import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { VehicleContext } from "../shared/contexts/VehicleContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: '1260px',
    padding: "0px 15px",
    display: "flex",
    flexDirection: 'column',
    "@media (max-width: 500px)": {
      padding: "0px 0px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      padding: "0px 0px",
    },
  },
  innerHolderFilter: {
    maxWidth: '1260px',
    width: '100%',
    margin: " 0 auto",
  },
  innerHolder: {
    // background: 'white',
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "1366px",
    margin: " 0 auto",
    padding: "0px",
    width: '100%',
    "@media (max-width: 500px)": {
      width: '100%',
      padding: " 20px 20px",
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      width: '100%',
      padding: " 20px 20px",
    },
  },
  card: {
    background: 'white',
    width: "calc(100% / 3 - 40px )",
    minWidth: "calc(100% / 3 - 40px )",
    cursor: 'pointer',
    borderRadius: '20px',
    margin: "20px ",
    padding: "20px",
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    "@media (max-width: 600px)": {
      width: "calc(100% / 1 )",
      minWidth: "calc(100% / 1  )",
      margin: "20px 0px",
    },
    "@media (min-width: 601px) and (max-width: 1300px)": {
      width: "calc(100% / 2 - 20px )",
      margin: "  10px ",
    },
  },
  cardTextHolder: {
    padding: "0px",
    color: 'white',
    "@media (max-width: 768px)": {},
  },
  cardTextHolderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  cta: {
    border: "none",
    margin: '0 auto',
    display: 'flex',
    padding: '10px 40px',
    background: '#083246',
    color: 'white',
    cursor: 'pointer'
  },
  cardTextTitleHolder: {
    marginBottom: '10px'
  },
  cardTextTitle: {
    fontSize: '20px',
    textTransform: 'uppercase',
    margin: '20px 0px',
    color: "#003145"
  },
  cardTextTitleAccent: {
    fontSize: '20px',
    color: "#53aca2"
  },
  cardTextTitleAccentMore: {
    fontSize: '16px',
    color: "#53aca2",
    textTransform: 'uppercase',
    margin: '5px 0px',
  },
  enquiryButton: {
    display: 'flex',
    padding: "10px 40px",
    justifyContent: 'center',
    textAlign: 'center',
    border: "none",
    cursor: "pointer",
    background: "#083246",
    borderRadius: "1rem",
    color: "white",
    "&:hover": {
      boxShadow: "0 0 5px 0 #4d0b0e ",
    },
    "@media (max-width: 959px)": {
      width: 'calc(100% / 2 - 20px)',
      padding: "10px",
    },
    "@media (max-width: 600px)": {
      width: "calc(100% / 1 )",
      minWidth: "calc(100% / 1 )",
    },
  },
}));

const ShowRoom = () => {
  const history = useHistory();
  const classes = useStyles();
  const { newVehiclesList } = useContext(VehicleContext);
  const [vehicles, setVehicles] = useState(newVehiclesList);

  useEffect(() => {
    setVehicles(newVehiclesList);
  }, [newVehiclesList]);

  const handleRedirect = (data) => {
    history.push(`/new-cars/${data?.make}/${data?.ownedModelId}`, data)
  };

  var formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 2
  });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.innerHolder}>
          {vehicles.flat().map((v, index) => {
            return (
              <div
                className={classes.card}
                onClick={() => {
                  handleRedirect(v);
                }}
              >
                <div className={classes.imageHolder}>
                  <img alt="" src={v?.image} width="100%" />
                </div>
                <div className={classes.cardTextHolder}>
                  <div className={classes.cardTextTitleHolder}>
                    <h5 className={classes.cardTextTitle}>  <strong><span className={classes.cardTextTitleAccent}>From</span> {formatter.format(v.price)}</strong></h5>
                    <button className={classes.enquiryButton}>More Details</button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ShowRoom;
