/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { DealerContext } from './DealerContext'

export const MakeContext = createContext();

export const MakeProvider = ({ children }) => {
  const getItem = (itm) => {
    let data = localStorage.getItem(itm);

    if (data && data != "undefined")
      return JSON.parse(data)
    else
      return {
      }
  }
  const setItem = (name, object) => {
    var stringObject = JSON.stringify(object);
    localStorage.setItem(name, stringObject);
  }

  const [make, setMake] = useState(getItem('activeMake'));
  const { websiteColors } = useContext(DealerContext)
  const [makeColors, setMakeColors] = useState({
    primaryColor: make?.primaryColor ? make?.primaryColor : websiteColors?.primaryColor,
    secondaryColor: make?.secondaryColor ? make?.secondaryColor : websiteColors?.secondaryColor,
    accentColor: make?.accentColor ? make?.accentColor : websiteColors?.accentColor,
    logo: make?.logo ? make?.logo : websiteColors?.logo
  })

  const setActiveGlobalMake = (activeMake) => {
    setItem('activeMake', activeMake);
    setMake(activeMake);
  }

  useEffect(() => {
    if (make?.name) {
      const colors = {
        primaryColor: make?.primaryColor ? make?.primaryColor : websiteColors?.primaryColor,
        secondaryColor: make?.secondaryColor ? make?.secondaryColor : websiteColors?.secondaryColor,
        accentColor: make?.accentColor ? make?.accentColor : websiteColors?.accentColor,
        logo: make?.logo ? make?.logo : websiteColors?.logo
      }
      setMakeColors(colors)
    } else {
      setMake(null)
    }
  }, [make])

  return (
    <MakeContext.Provider value={{ make, makeColors, setActiveGlobalMake, setMake }}>
      {children}
    </MakeContext.Provider>
  )
};