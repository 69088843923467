/* eslint-disable array-callback-return */
import React from 'react'
import Typography from '@material-ui/core/Typography';
import DealsFilter from './DealsFilter';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 0px',
    paddingTop: '135px',
    minHeight: 'calc(100vh - 390px)',
  },
  background: {
    padding: '40px 15px'
  },
  backgroundInner: {
    padding: '40px 15px',
    background: 'white',
    maxWidth: '1260px',
    margin: "0 auto",
  },
  title: {
    fontSize: '2rem',
    color: 'white',
    textTransform: 'uppercase',
  },
  cardHolder: {
    maxWidth: '1170px',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '0 auto',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '20px',
    padding: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    width: '100%',
    margin: '10px',
    maxWidth: 'calc(100% / 3 - 40px )',
    cursor: 'pointer',
    "@media (max-width: 770px)": {
      maxWidth: 'calc(100% / 1 - 20px)',
    },
    "@media (min-width: 769px) and (max-width: 1200px)": {
      maxWidth: 'calc(100% / 2 - 20px)',
    },
    "&:hover": {
      boxShadow: 'none',
    },
  },
  newSecondaryColorC: {
    color: "#53aca2",
    textAlign: 'center',
    fontSize: '16px'
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  middleSubtitle: {
    fontSize: "1.5rem",
    color: "#466574",
    textAlign: 'center',
    marginTop: '20px',
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto'
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
  vehicleTitle: {
    color: "#063246",
    fontSize: '1.5rem',
    padding: '20px 0px',
    textTransform: 'uppercase',
  },
  headingTitle: {
    color: "#063246",
    fontSize: '1.5rem',
    textTransform: 'uppercase',
  },
  cardHeadlineHolder: {
    height: '80px',
  },
  offerHeadline: {
    fontSize: '1rem',
  },
  ctaTop: {
    padding: "10px 40px",
    border: "none",
    cursor: "pointer",
    background: "#083246",
    margin: "10px",
    borderRadius: "1rem",
    color: "white",
    "&:hover": {
      boxShadow: "0 0 5px 0 #4d0b0e ",
    },
    "@media (max-width: 959px)": {
      width: 'calc(100% / 2 - 20px)',
      padding: "10px",
    },
  },
}));

export default function SpecialDeals() {
  const classes = useStyles();
  const history = useHistory();
  const sortDirection = 'Desc';
  const pageNumber = 1;

  const {
    deals,
  } = DealsFilter(pageNumber, sortDirection)

  const handlePageChange = (deal) => {
    history.push(`/special-deals/${deal?.make}/${deal?.offerId}`, deal)
  }

  return (
    <div className={classes.root}>
      <div className={classes.middleNew}>
        <Typography variant="h1" className={classes.middleTile}>
          <strong><span className={classes.newSecondaryColor}>Our</span> Deals</strong>
        </Typography>
        <Typography variant="h2" className={classes.middleSubtitle}>
          Take advantage of our exclusive deals!
        </Typography>
        <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
      </div>
      <div className={classes.background}>
        <div className={classes.cardHolder}>
          {
            (deals.length > 0) &&
            deals.map((deal, index) => {
              return (
                <div key={index} className={classes.card} >
                  <Typography variant="h4" className={classes.vehicleTitle}>
                    <span>{deal?.make} {deal?.model}</span>
                    <Divider className={classes.newMiddleDivider} />
                  </Typography>
                  <img alt="" onClick={() => { handlePageChange(deal) }} src={deal?.imageUrl} width="100%" />
                  <div className={classes.cardHeadlineHolder}><Typography variant="h4" className={classes.headingTitle}>
                    <span className={classes.offerHeadline}>{deal?.headline}</span>
                  </Typography></div>
                  <div style={{ height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                      className={classes.ctaTop}
                      onClick={() => { handlePageChange(deal) }}
                    >
                      View Offer
                    </button>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}
