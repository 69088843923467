import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import ContactComponentPopi from '../shared/ContactComponentPopi'

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(10, 0, 6),
    '@media (max-width: 1024px)': {
      padding: theme.spacing(10, 0, 6),
    },
  },
  dividerTitle: {
    backgroundColor: '#BB2127',
    width: '33%',
    marginBottom: '0px',
    height: '3px',
    margin: '20px auto'
  },
  bold: {
    fontWeight: 700
  },
  listItem: {
    listStyle: 'lower-alpha',
  },
  url: {
    color: '#7c7c7c',
    textDecoration: 'none',
    '&:hover': {
      color: '#1f2532',
      textDecoration: 'none'
    }
  },
  link: {
    backgroundColor: '#E20A17',
    marginBottom: '1rem',
    color: '#ffffff',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8
    }
  }
}));

const Popia = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div className={classes.content}>

        <Grid container spacing={6}>

          <Grid item xs={12} md={4}>
            <Typography gutterBottom variant="h4" color="textPrimary" fontWeight="500">
              POPI REQUEST
            </Typography>
            <ContactComponentPopi leadTypeId={3} />
          </Grid>

          <Grid item xs={12} md={7}>
            <Typography gutterBottom variant="h5" color="textPrimary" fontWeight="500">
              Section 11 Of PROTECTION OF PERSONAL INFORMATION ACT (POPIA)
            </Typography>

            <p>
              Consent, justification and objection
            </p>

            <ol>
              <li>
                Personal information may only be processed if—
              </li>

              <ul className={classes.listItem}>
                <li>
                  the data subject or a competent person where the data subject is a child consents to the processing;
                </li>
                <li>
                  processing is necessary to carry out actions for the conclusion or performance of a contract to which the data subject is party;
                </li>
                <li>
                  processing complies with an obligation imposed by law on the responsible party;
                </li>
                <li>
                  processing protects a legitimate interest of the data subject;
                </li>
                <li>
                  processing is necessary for the proper performance of a public law duty by a public body; or
                </li>
                <li>
                  processing is necessary for pursuing the legitimate interests of the responsible party or of a third party to whom the information is supplied.
                </li>
              </ul>
              <br />
              <li>
                Personal information may only be processed if—
              </li>

              <ul className={classes.listItem}>
                <li>

                  The responsible party bears the burden of proof for the data subject’s or competent person’s consent as referred to in subsection (1)(a).
                </li>

                <li>
                  The data subject or competent person may withdraw his, her or its consent, as referred to in subsection (1)(a), at any time: Provided that the lawfulness of the processing of personal information before such withdrawal or the processing of personal information in terms of subsection (1)(b) to (f) will not be affected.
                </li>
              </ul>
              <br />
              <li>
                A data subject may object, at any time, to the processing of personal information—
              </li>

              <ul className={classes.listItem}>
                <li>
                  in terms of subsection (1)(d) to (f), in the prescribed manner, on reasonable grounds relating to his, her or its particular situation, unless legislation provides for such processing; or
                </li>
                <li>
                  for purposes of direct marketing other than direct marketing by means of unsolicited electronic communications as referred to in section 69.
                </li>

              </ul>
              <br />
              <li>
                If a data subject has objected to the processing of personal information in terms of subsection (3), the responsible party may no longer process the personal information.
              </li>

            </ol>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default Popia;