/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { BASE_URI, SUZUKI_MASTER_DEALERID } from "../shared/Constants";
import SharedCarousel from "../shared/Carousel/SharedCarousel";
import CarouselCar from "../shared/CarouselCar/CarouselCar";
import Divider from '@mui/material/Divider';
import DealsFilter from '../../components/SpecialDeals/DealsFilter';
import BackImg from '../shared/assets/Background_Colour_White.svg'
import bookService from '../shared/assets/bookService.jpg'
import middleTop from '../shared/assets/pre-owned.jpg'
import middleBottom from '../shared/assets/finance.jpg'
import Aftersales from '../shared/assets/Aftersales.jpg'
import { DealerContext } from '../shared/contexts/DealerContext';
import ShowRoom from "../Showroom/Showroom";
import BannerCTA from "../bannerCTA";
import TDSVG from "../shared/assets/book-a-test-drive.svg";
import SVSVG from "../shared/assets/book-a-service.svg";
import FADSVG from "../shared/assets/find-a-dealer.svg";

const useStyles = makeStyles(() => ({
  img: {
    height: "100%",
    maxHeight: "160px",
    margin: "10px 0px",
    transition: "0.5s ease all",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)",
    },
  },
  centerCintentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    maxWidth: '1360px',
    margin: 'auto',
    background: 'white',
    padding: '30px 40px 0px 40px',
  },
  middleNew: {
    width: '100%',
    margin: 'auto',
    marginBottom: '30px',
  },
  blueSection: {
    background: '#21918650',
    margin: '0 auto',
    maxWidth: '1360px',
    backgroundImage: `url(${BackImg})`,
    "@media (max-width: 768px)": {
      padding: '20px 0px'
    },
  },
  textOverlay: {
    position: 'absolute',
    top: '0px',
    background: '#21918642',
    width: '100%',
    left: '0',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '33px',
    flexDirection: 'column',
    color: "white",
  },
  blueSectionInner: {
    display: 'flex',
    width: '100%',
    padding: '40px',
    "@media (max-width: 500px)": {
      flexDirection: 'column',
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      flexDirection: 'column',
    },
  },
  imgHolder: {
    position: 'relative',
    height: '100%',
  },
  blueSectionInnerLeft: {
    width: 'calc(100% / 3 - 40px)',
    margin: '20px',
    transition: 'all 0.5s ease',
    backgroundPosition: '100% 100%',
    textAlign: 'center',
    color: 'white',
    "@media (max-width: 500px)": {
      width: 'calc(100% / 1 )',
      margin: '20px 0px',
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      width: 'calc(100% / 1 )',
      margin: '20px 0px',
    },
    cursor: 'pointer',
    "&:hover": {
      boxShadow: '0px 20px 40px rgb(0 0 0 / 71%)',
      scale: '0.9',
    }
  },
  blueSectionInnerMiddle: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% / 3 - 40px)',
    margin: '20px',
    justifyContent: "space-between",
    "@media (max-width: 500px)": {
      width: 'calc(100% / 1 )',
      margin: '20px 0px',
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      width: 'calc(100% / 1 )',
      margin: '20px 0px',
    },
  },
  blueSectionInnerRight: {
    position: 'relative',
    width: 'calc(100% / 3 - 40px)',
    margin: '20px',
    backgroundPosition: '100% 100%',
    textAlign: 'center',
    color: 'white',
    transition: 'all 0.5s ease',
    "@media (max-width: 500px)": {
      width: 'calc(100% / 1 )',
      margin: '20px 0px',
    },
    "@media (min-width:501px) and (max-width: 768px)": {
      width: 'calc(100% / 1 )',
      margin: '20px 0px',
    },
    cursor: 'pointer',
    "&:hover": {
      boxShadow: '0px 20px 40px rgb(0 0 0 / 71%)',
      scale: '0.9',
    }
  },
  blueSectionInnerMiddleTop: {
    position: 'relative',
    marginBottom: '40px',
    backgroundPosition: '100% 100%',
    textAlign: 'center',
    color: 'white',
    cursor: 'pointer',
    transition: 'all 0.5s ease',
    "&:hover": {
      boxShadow: '0px 20px 40px rgb(0 0 0 / 71%)',
      scale: '0.9',
    }
  },
  blueSectionInnerMiddleBottom: {
    position: 'relative',
    backgroundPosition: '100% 100%',
    textAlign: 'center',
    color: 'white',
    cursor: 'pointer',
    transition: 'all 0.5s ease',
    "&:hover": {
      boxShadow: '0px 20px 40px rgb(0 0 0 / 71%)',
      scale: '0.95',
    }
  },
  topTile: {
    paddingTop: '2rem',
    fontSize: "2.5rem",
    textAlign: 'center',
    color: "#003145"
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  middleSubtitle: {
    fontSize: "1.5rem",
    color: "#466574",
    textAlign: 'center',
    marginTop: '20px',
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto'
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
}));


const HomeComponent = () => {
  const classes = useStyles();
  const [slides, setSlides] = useState();
  const { globalDealer } = useContext(DealerContext)

  const mountedRef = useRef(true);

  const ToolsSlides = [
    {
      component:
        <Link key='link_to_find_a_dealer' style={{ display: 'flex', height: '100%', width: 'auto', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', textAlign: 'center' }} to={globalDealer?.dealerId == SUZUKI_MASTER_DEALERID ? '/branch-locator' : '/contact-us'}>
          <div style={{ display: 'flex', width: 'auto', height: '200px', borderRadius: '9999px', borderWidth: '3px', borderColor: '#003145', padding: '1px', alignItems: 'center', justifyContent: 'center' }}>
            <img alt="" style={{ display: 'flex', width: 'auto', height: '100%', aspectRatio: '1:1' }} src={FADSVG} />
          </div>
          <h3 style={{ fontWeight: 700, color: "#003145" }}>{globalDealer?.dealerId == SUZUKI_MASTER_DEALERID ? 'FIND A DEALER' : 'CONTACT US'}</h3>
        </Link>
    },
    {
      component:
        <Link key={`link_to_book_a_test_drive`} style={{ display: 'flex', height: '100%', width: 'auto', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', textAlign: 'center' }} to='/contact-us'>
          <div style={{ display: 'flex', width: 'auto', height: '200px', borderRadius: '9999px', borderWidth: '3px', borderColor: '#003145', padding: '1px', alignItems: 'center', justifyContent: 'center' }}>
            <img alt="" style={{ display: 'flex', width: 'auto', height: '100%', aspectRatio: '1:1' }} src={TDSVG} />
          </div>
          <h3 style={{ fontWeight: 700, color: "#003145" }}>BOOK A TEST DRIVE</h3>
        </Link>
    },
    {
      component:
        <Link key={`link_to_book_a_test_drive`} style={{ display: 'flex', height: '100%', width: 'auto', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', textAlign: 'center' }} to='/book-a-service'>
          <div style={{ display: 'flex', width: 'auto', height: '200px', borderRadius: '9999px', borderWidth: '3px', borderColor: '#003145', padding: '1px', alignItems: 'center', justifyContent: 'center' }}>
            <img alt="" style={{ display: 'flex', width: 'auto', height: '100%', aspectRatio: '1:1' }} src={SVSVG} />
          </div>
          <h3 style={{ fontWeight: 700, color: "#003145" }}>BOOK A SERVICE</h3>
        </Link>
    }
  ];

  useEffect(() => {
    if (globalDealer) {
      const getSlides = async () => {
        try {
          const params = {
            pageNumber: 0,
            pageSize: 100,
            status: "active",
            dealerId: globalDealer?.dealerId,
          };
          const result = await axios({
            method: "GET",
            url: `${BASE_URI}/slide`,
            params
          });
          // eslint-disable-next-line array-callback-return
          console.log('tempSlides', result.data.list);
          let tempSlides = result.data.list.map((itm) => {
            if (itm?.dealerID == globalDealer?.dealerId) {
              return {
                img: itm.slideImageUrl,
                isVideo: itm.isVideo,
                targetUrl: itm.url,
              };
            };
          });
          setSlides(tempSlides);
        } catch (error) {
        }
      };

      getSlides().then(() => {
        if (!mountedRef.current) return null;
      });

      return () => {
        mountedRef.current = false;
      };
    }
  }, [globalDealer]);

  const {
    deals,
  } = DealsFilter(1, 'Desc');

  return (
    <div style={{ paddingTop: '79px' }}>
      {slides?.length > 0 && <SharedCarousel slides={slides} />}
      <div className={classes.centerCintentContainer}>
        <Typography variant="h1" className={classes.topTile}>
          <strong>Welcome to {globalDealer?.name.replace("Master", "")}</strong>
        </Typography>
        <BannerCTA
          slides={ToolsSlides}
          containerStyle={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '30px 30px', margin: '45px 0px' }}
          contentStyle={{ display: 'flex', minWidth: '33%', height: '200px', justifyContent: 'center', alignItems: 'center', padding: '30px 30px', marginBottom: '45px' }}
        />
        <div className={classes.newVehicleSection}>
          <div className={classes.middleNew}>
            <Typography variant="h1" className={classes.middleTile}>
              <strong><span className={classes.newSecondaryColor}>Explore</span> Our Range</strong>
            </Typography>
            <Typography variant="h2" className={classes.middleSubtitle}>
              explore Suzuki's range of affordable cars
            </Typography>
            <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
          </div>
        </div>
      </div>
      <div className={classes.centerCintentContainer}>
        <ShowRoom />
        {deals.length > 0 &&
          <>
            <div className={classes.middleNew}>
              <Typography variant="h1" className={classes.middleTile}>
                <strong><span className={classes.newSecondaryColor}>Our</span> Special Deals</strong>
              </Typography>
              <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
            </div>
            <CarouselCar slides={deals} />
          </>
        }
      </div>

      <div className={classes.blueSection}>
        <div className={classes.blueSectionInner}>
          <div className={classes.blueSectionInnerLeft}>
            <Link
              to="/book-a-service"
            >
              <div className={classes.imgHolder}>
                <img alt="" src={bookService} width="100%" height="100%" />
                <div className={classes.textOverlay}>
                  Book a service
                  <Divider className={classes.newMiddleDivider} />
                </div>
              </div >
            </Link>
          </div>
          <div className={classes.blueSectionInnerMiddle}>
            <div className={classes.blueSectionInnerMiddleTop}>
              <Link
                to="/pre-owned"
              >
                <div className={classes.imgHolder}>
                  <img alt="" src={middleTop} width="100%" height="100%" />
                  <div className={classes.textOverlay}>
                    Pre-Owned
                    <Divider className={classes.newMiddleDivider} />
                  </div>
                </div>
              </Link>
            </div>
            <div className={classes.blueSectionInnerMiddleBottom}>
              <Link
                to="/apply-for-finance"
              >
                <div className={classes.imgHolder}>
                  <img alt="" src={middleBottom} width="100%" height="100%" />
                  <div className={classes.textOverlay}>
                    Finance
                    <Divider className={classes.newMiddleDivider} />
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className={classes.blueSectionInnerRight}>
            <Link
              to="/parts"
            >
              <div className={classes.imgHolder}>
                <img alt="" src={Aftersales} width="100%" height="100%" />
                <div className={classes.textOverlay}>
                  Parts
                  <Divider className={classes.newMiddleDivider} />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
