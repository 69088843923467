import React, { useContext } from 'react'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { MASTER_DEALERID, BASE_URI } from '../shared/Constants'
import { Parts as PartsForm } from '@red-build/leadcentre';
import { DealerContext } from '../shared/contexts/DealerContext';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingTop: '130px',
    marginBottom: '60px'
  },
  innerSection: {
    width: "100%",
    maxWidth: "1360px",
    display: "flex",
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center',
    padding: "6px 15px",
    '@media (max-width: 959px)': {
      padding: '1rem',
    },
  },
  section: {
    width: '50vw',
    marginTop: '5rem',
    '@media (max-width: 959px)': {
      width: '100%',
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    fontSize: '44px',
    fontWeight: 'bold'
  },
  titleTopAccent: {
    fontSize: '52px',
    color: '#209287',
    fontWeight: 'bold'
  },
  middleTile: {
    fontSize: "2.5rem",
    textAlign: 'center',
    paddingTop: '40px',
    color: "#003145"
  },
  middleSubtitle: {
    fontSize: "1.5rem",
    color: "#466574",
    textAlign: 'center',
    marginTop: '20px',
  },
  deviderContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  newMiddleDivider: {
    background: "#53aca2",
    width: '100px',
    height: '5px',
    margin: 'auto'
  },
  newSecondaryColor: {
    color: "#53aca2",
  },
}))

const Parts = () => {
  const classes = useStyles();
  const { globalDealer, dealerList } = useContext(DealerContext)

  const defaultColors = {
    primaryColor: "#083246",
    secondaryColor: "#EFDF00",
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
          <Typography variant="h1" className={classes.middleTile}>
            <strong><span className={classes.newSecondaryColor}>Request</span> A Part</strong>
          </Typography>
          <Typography variant="h2" className={classes.middleSubtitle}>
            Leave your details and one of our service advisors will contact you shortly.
          </Typography>
          <div className={classes.deviderContainer}><Divider className={classes.newMiddleDivider} /></div>
        <div className={classes.section}>
          <PartsForm
            variant='standard'
            masterDealerId={MASTER_DEALERID}
            dealerId={globalDealer?.dealerId}
            //activeDealerId={globalDealer?.dealerId} 
            dealerName={globalDealer?.name?.replace("Master", " ")}
            activeDealerId={globalDealer?.dealerId}
            base_uri={BASE_URI}
            dealerList={dealerList}
            websiteColors={defaultColors}
            leadTypeId={6}
          />
        </div>
      </div>
    </div>
  )
}

export default Parts