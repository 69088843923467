/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '60vh',
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
  },
  Carousel: {
    //transform: (slidePosition) => `translate(${slidePosition}%, 0px)`,
    width: "100%",
    maxWidth: '1360px',
    justifyContent: "space-evenly",
    alignItems: "center",
    objectFit: 'contain',
    transition: "0.5s all ease-in",
  },
  carouselBack: {
    left: '15%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%);',
    all: 'unset',
    cursor: 'pointer',
    color: 'white',
    width: '42px',
    height: '42px',
    opacity: '0.4',
    '&:hover': {
      opacity: '1',
    }
  },
  carouselNext: {
    right: '15%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%);',
    all: 'unset',
    cursor: 'pointer',
    color: 'white',
    width: '42px',
    height: '42px',
    opacity: '0.4',
    '&:hover': {
      opacity: '1',
    }
  },
  nextbtn: {
    background: 'transparent',
    border: 'black 3px solid',
    borderRadius: '50%',
    right: "0",
    padding: "10px",
    color: "black",
    opacity: "0.5",
    transition: "0.1s all ease-in",
    "&:hover": {
      background: 'transparent',
      border: 'white 3px solid',
      color: "white",
      opacity: "0.8",
      cursor: 'pointer'
    },
  },
  backbtn: {
    background: 'transparent',
    border: 'black 3px solid',
    borderRadius: '50%',
    left: "0",
    padding: "10px",
    color: "black",
    opacity: "0.5",
    transition: "0.1s all ease-in",
    "&:hover": {
      background: 'transparent',
      border: 'white 3px solid',
      color: "white",
      opacity: "0.8",
      cursor: 'pointer'
    },
  },
  btnHolder: {
    display: "flex",
    position: "absolute",
    width: "97%",
    justifyContent: "space-between",
  },
  img: {
    filter: 'brightness(0.9)',
  },
  anchor: {
    display: 'block',
    lineHeight: '0px',
  },

}));

export default function SharedCarousel(props) {
  const [currentSlide, setCurrentSlide] = useState(props?.slides[0] || null);
  const currentSlideIndexRef = useRef(0);
  const carouselPausedRef = useRef(false);
  const classes = useStyles(currentSlideIndexRef.current);

  useEffect(() => {
    if (props?.slides?.length > 0) {
      const interval = setInterval(() => {
        carouselScroll();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [props?.slides]);

  const carouselScroll = () => {
    if (carouselPausedRef.current === true) {
      return;
    }
    handleNextSlide();
  };

  const handleVideoStart = () => {
    carouselPausedRef.current = true;
  };

  const handleVideoEnded = (e) => {
    carouselPausedRef.current = false;
  };

  const handleNextSlide = () => {
    let newIndex = 0;
    if (currentSlideIndexRef.current < props.slides.length - 1) {
      newIndex = currentSlideIndexRef.current + 1;
    }
    currentSlideIndexRef.current = newIndex;
    setCurrentSlide(props.slides[newIndex]);
    handleVideoEnded();
  };

  const handlePreSlide = () => {
    let newIndex = props.slides.length - 1;
    if (currentSlideIndexRef.current > 0) {
      newIndex = currentSlideIndexRef.current - 1;
    }
    currentSlideIndexRef.current = newIndex;
    setCurrentSlide(props.slides[newIndex]);
    handleVideoEnded();
  };

  return (
    <div className={classes.root}>
      <div className={classes.Carousel}>
        <a href={currentSlide?.targetUrl ?? "/apply-for-finance"} className={classes.anchor}>
          {/* {currentSlide?.content}
          { */}
            {currentSlide?.isVideo
              ? <video controls={false} width="100%" autoPlay onEnded={handleVideoEnded} onPlay={handleVideoStart}>
                <source type='video/mp4' src={currentSlide?.img} />
                <source type="video/webm" src={currentSlide?.img} />
                <source type="video/ogg" src={currentSlide?.img} />
                Your browser does not support this video.
              </video>
              : <img alt="" style={{objectFit: 'contain', width: '100%', height: 'auto'}} tag="div" src={currentSlide?.img ?? currentSlide} />}
          {/* } */}
        </a>
      </div>
      <div className={classes.btnHolder}>
        <button className={classes.carouselBack} onClick={handlePreSlide}>
          <ChevronLeftIcon style={{ fontSize: 40, backgroundColor: 'transparent' }} />
        </button>
        <button className={classes.carouselNext} onClick={handleNextSlide}>
          <ChevronRightIcon style={{ fontSize: 40 }} />
        </button>
      </div>
    </div>
  );
}