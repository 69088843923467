/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI, MASTER_DEALERID, MOTORGROUPID, MASTER_DEALER_NAME } from '../Constants'
import { GAInit } from '@red-build/leadcentre';

const MASTER_WEBSITE_COLORS = {
  primaryColor: '#d12519',
  secondaryColor: '#000',
  accentColor: '#d12519'
};

const MASTER_DEALER = {
  dealerId: MASTER_DEALERID,
  name: MASTER_DEALER_NAME,
  websiteColors: MASTER_WEBSITE_COLORS
};

export const DealerContext = createContext();

export const DealerProvider = ({ children }) => {
  const [dealerContextLoading, setDealerContextLoading] = useState(true);
  const [dealerList, setDealerList] = useState([]);
  const [dealerListDirty, setDealerListDirty] = useState([]);
  const [globalDealer, setGlobalDealer] = useState();
  const [dealerSocials, setDealerSocials] = useState();
  const [websiteColors, setWebsiteColors] = useState();
  const [masterDealer, setMasterDealer] = useState();
  const [slides, setSlides] = useState([]);

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, PUT",
      "Access-Control-Allow-Headers": "Content-Type"
    }
  }

  // Functions

  const getDealers = async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URI}/Dealers/DealerContactDetailsByMotorgroup/${MOTORGROUPID}`,
        headers: config.headers,
      });
      const filtered = response.data.filter((d) => d.relatedBrands.find((b) => b.id === 8074));
      const stripped = filtered.filter((d) => !d.name.includes('Master'));
      setDealerList(stripped);
      setDealerListDirty(filtered || [MASTER_DEALER]);
    } catch (e) {
      console.error('There was an error getting all Dealers', e);
    }
  };

  const lookupDealer = async (name) => {
    await axios({
      method: 'GET',
      url: `${BASE_URI}/Dealers/${MOTORGROUPID}/${name}`,
    })
      .then((response) => {
        const socials = response?.data?.social;
        if (socials?.googleUniversalAnalyticsCode.length > 0) {
          GAInit(socials?.googleUniversalAnalyticsCode);
        }
        setDealerSocials(socials);
      })
      .catch((e) => {
        console.error('There was an error getting Dealer socials', e);
      });
  }

  const assignDealer = (fallbackDealerId) => {
    setDealerContextLoading(true);
    const host = window.location.host;
    // const host = 'mekorsuzuki.co.za.dedi616.jnb3.host-h.net'; //SUZUKI MASTER
    // const host = 'suzukiconstantiaberg.co.za';
    // const host = 'suzukitygervalley.co.za';
    // const host = 'suzukikenilworth.co.za';
    const foundDealers = dealerListDirty.find((v) => {
      const dealerUrl = new URL(v.websiteUrl);
      if (dealerUrl.host.replace('www.', '') === host.replace('www.', '')) {
        return true;
      }
    });
    if (foundDealers) {
      setGlobalDealer(foundDealers);
      lookupDealer(foundDealers.name);
      setWebsiteColors(foundDealers.websiteColors);
    } else {
      const fallbackDealer = dealerListDirty.find(d => d.dealerId == fallbackDealerId);
      if (fallbackDealer) {
        setGlobalDealer(fallbackDealer);
        lookupDealer(fallbackDealer.name);
        setWebsiteColors(fallbackDealer.websiteColors);
      }
    };
    setDealerContextLoading(false);
  };

  // All useEffects

  useEffect(() => {
    const asyncDealerLists = async () => {
      setDealerContextLoading(true);
      await getDealers();
      setDealerContextLoading(false);
    };
    asyncDealerLists();
  }, [])

  useEffect(() => {
    if (!globalDealer) return;
    const params = {
      dealerId: globalDealer.dealerId,
      pageNumber: 1,
      pageSize: 25,
    };
    axios({
      method: 'GET',
      url: `${BASE_URI}/Slide`,
      params,
    }).then((response) => {
      const tempSlides = response.data?.list?.map((itm) => ({
        img: itm.slideImageUrl,
        isVideo: itm.isVideo,
        targetUrl: itm.url,
        buttonText: itm.buttonText,
        make: itm.make,
        cta: itm.cta,
      }));
      setSlides(tempSlides);
    }).catch((e) => {
      console.error('There was an error getting slides for Dealer', e)
    });
  }, [globalDealer]);

  useEffect(() => {
    if (dealerListDirty) {
      assignDealer(584);
      const oMasterDealer = dealerListDirty.find(d => d.dealerId == MASTER_DEALERID);
      if (oMasterDealer) {
        setMasterDealer(oMasterDealer);
      }
      setDealerContextLoading(false);
    }
  }, [dealerListDirty]);

  return (
    <DealerContext.Provider value={{ dealerContextLoading, globalDealer, websiteColors, dealerListDirty, dealerList, assignDealer, dealerSocials, masterDealer, slides }}>
      {children}
    </DealerContext.Provider>
  )
};