import React, { useContext } from 'react'
import { SellYourVehicle } from '@red-build/leadcentre'
import { makeStyles } from '@material-ui/core'
import { BASE_URI, WEPAY4CARS_URI } from '../shared/Constants'
import { DealerContext } from '../shared/contexts/DealerContext'

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: '70px',
  },
  imgHolder: {
    maxWidth: '1170px',
  },
  innerSection: {
    maxWidth: '1170px',
  }
}))

const SellVehicle = () => {
  const { globalDealer, dealerList } = useContext(DealerContext)
  const classes = useStyles();
  const defaultColors = {
    primaryColor: "#219287",
    secondaryColor: "#219287",
    accentColor: "#219287",
    paperColor: '#ff000000',

  };
  return (
    <div className={classes.root}>
      <div className={classes.imgHolder}>
      </div>
      <div className={classes.innerSection}>
        <SellYourVehicle base_uri={BASE_URI} wePay4Cars_uri={WEPAY4CARS_URI} dealerId={globalDealer.dealerId} active leadSourceId={15} websiteColors={defaultColors} dealerList={dealerList} paper={true} resetForm={true} />
      </div>
    </div>
  )
}

export default SellVehicle