/* eslint-disable eqeqeq */
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, MASTER_CONFIGURATOR_DEALERID, SUZUKI_MASTER_DEALERID, MOTORGROUPID } from '../Constants.js'
import { DealerContext } from './DealerContext.js';

export const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [vehiclesList, setVehiclesList] = useState([]);
  const brandedList = [];
  const [newVehiclesList, setNewVehiclesList] = useState([]);
  const { globalDealer, dealerList } = useContext(DealerContext);

  useEffect(() => {
    const params = {
      pageNumber: 1,
      pageSize: 1000,
    };

    if (globalDealer?.dealerId != SUZUKI_MASTER_DEALERID) {
      params.dealerId = globalDealer?.dealerId;
    } else {
      params.motorGroupId = MOTORGROUPID;
      // params.dealerId = 486;
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/stock`,
      params,
    }).then((response) => {
      const dealerIDs = dealerList?.map((d) => {
        return d?.dealerId;
      });
      // Filter stock by dealerList
      const data = response?.data?.list?.filter((s) => (dealerIDs?.includes(s?.dealerId)));
      setVehiclesList(data);
    }).catch(error => {
      if (axios.isCancel(error)) return
    }).finally(() => {
      setLoading(false);
    });
  }, [globalDealer, dealerList])

  const getNewVehicles = async (dealerID) => {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, PUT',
      'Access-Control-Allow-Headers': 'Content-Type',
    };
    const params = {
      dealerId: dealerID,
      pageNumber: 1,
      pageSize: 20000,
    };

    return axios({
      method: 'GET',
      url: `${BASE_URI}/dealermodels`,
      params,
      headers,
    })
      .then((response) => {
        const arr = globalDealer?.relatedBrands.map((v) =>
          response.data?.list.filter((va) => va.makeId === v.id)
        );
        return arr.flat();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error while fetching dealer models.', error);
      });
  };

  const getAllNewVehicles = async () => {
    const dealerVehicles = await getNewVehicles(globalDealer?.dealerId);
    const masterVehicles = await getNewVehicles(MASTER_CONFIGURATOR_DEALERID);
    // eslint-disable-next-line array-callback-return
    const dVModelID = dealerVehicles?.map((dV) => {
      if (dV?.modelId) {
        return dV?.modelId;
      }
      return 0;
    });

    const masterVehiclesFiltered = masterVehicles?.filter((mV) => !dVModelID.includes(mV.modelId));

    if (masterVehiclesFiltered || dealerVehicles) {
      setNewVehiclesList([...masterVehiclesFiltered, ...dealerVehicles]);
    }
  };

  const getVehiclePromise = async () => {
    setNewVehiclesList([]);
    await getAllNewVehicles();
  };

  useEffect(() => {
    if (globalDealer) {
      getVehiclePromise();
    }
  }, [globalDealer]);

  return (
    <VehicleContext.Provider value={{ loading, vehiclesList, setVehiclesList, brandedList, newVehiclesList }}>
      {children}
    </VehicleContext.Provider>
  )
};